import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }
  updateTitle(title: string= null) {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'og:title', content: title});
    this.meta.updateTag({name: 'og:locale', content: 'id'});
    this.meta.updateTag({name: 'og:site_name', content: 'Portal Resmi Pemerintah Kabupaten Jombang'});
  }
  updateMetaInfo(desc, keywords, url) {
    this.meta.updateTag({ name: 'description', content: desc });
    if (keywords) { this.meta.updateTag({ name: 'keywords', content: keywords }); }
    this.meta.updateTag({ name: 'og:url', content: url });
    this.meta.updateTag({ name: 'og:description', content: desc });
}
}
