import { Injectable } from '@angular/core';
import { HelpersService } from './helpers.service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
export interface Login {
  user: any;
  token: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(
    private http: HttpClient,
    private router: Router,
    private helper: HelpersService,
    ) {
      this.token = localStorage.getItem(this.helper.localToken);
    }
    token: string;
    isLoggedIn(): boolean {
      if (localStorage.getItem(this.helper.localUser)) {
        return true;
      } else {
        return false;
      }
    }
    login(username: string, password: string) {
      const auth = 'Basic ' + btoa(`${username}:${password}`);
      const headers = new HttpHeaders().append('Authorization', auth);
      const url = '/auth/login';
      return this.http.get<Login>(url, {headers});
    }
    logout() {
      localStorage.removeItem(this.helper.localUser);
      localStorage.removeItem(this.helper.localToken);
      this.http.get('/auth/logout').subscribe(() => {
        this.router.navigate(['login']);
      });
    }
}
