import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';

export class ImageUpload {
    loader; urlOrObject; t; xhr;
    constructor(loader, urlOrObject) {
        this.loader = loader;
        this.urlOrObject = urlOrObject;
        // this.t = t;
    }
    localhost = isDevMode() ? environment.host + ':' + environment.port : environment.host;
    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest(reject);
        });
    }
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        let url = this.urlOrObject;
        let headers = null;
        if (typeof(this.urlOrObject) === 'object') {
            url = this.urlOrObject.url;
            headers = this.urlOrObject.headers;
        }
        xhr.withCredentials = false;
        xhr.open('POST', this.localhost + url, true);
        if (headers !== null) {
            for (const key in headers) {
                if (typeof(headers[key]) === 'function') {
                    xhr.setRequestHeader(key, headers[key]());
                } else {
                    xhr.setRequestHeader(key, headers[key]);
                }
            }
        }
        xhr.responseType = 'json';
    }
    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericError = 'Cannot upload file';
        xhr.addEventListener('error', () => reject(genericError));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;
            if (!response || !response.uploaded) {
                return reject(response && response.message ? response.message : genericError);
            }
            resolve({
                default: response.url
            });
        });
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }
    _sendRequest( reject ) {
        this.loader.file
        .then( file => {
            // Prepare the form data.
            const data = new FormData();
            data.append( 'upload', file );
            // Important note: This is the right place to implement security mechanisms
            // like authentication and CSRF protection. For instance, you can use
            // XMLHttpRequest.setRequestHeader() to set the request headers containing
            // the CSRF token generated earlier by your application.
            // Send the request.
            const sess = JSON.parse(localStorage.getItem('x-nigol-jokab'));
            this.xhr.setRequestHeader('X-XSRF-TOKEN', sess.token);
            this.xhr.send( data );
        } )
        .catch( reject );
    }
}
