import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landingpage/landingpage.module').then(m => m.LandingPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'berita',
    loadChildren: () => import('./pages/berita/berita.module').then(m => m.BeritaModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then(m => m.DownloadModule)
  },
  {
    path: 'kegiatan',
    loadChildren: () => import('./pages/kegiatan/kegiatan.module').then(m => m.KegiatanModule)
  },
  {
    path: 'pengumuman',
    loadChildren: () => import('./pages/pengumuman/pengumuman.module').then(m => m.PengumumanModule)
  },
  {
    path: 'galeri',
    loadChildren: () => import('./pages/galeri/galeri.module').then(m => m.GaleriModule)
  },
  {
    path: 'wisata',
    loadChildren: () => import('./pages/wisata/wisata.module').then(m => m.WisataModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/halaman/halaman.module').then(m => m.HalamanModule)
  },
  {
    path: 'opd',
    loadChildren: () => import('./pages/opd/opd.module').then(m => m.OpdModule)
  },
  {
    path: 'sakip',
    loadChildren: () => import('./pages/sakip/sakip.module').then(m => m.SakipModule)
  },
  {
    path: 'integrasi-jdih',
    loadChildren: () => import('./pages/integrasi-jdih/integrasi.module').then(m => m.KegiatanModule)
  },
  {
    path: 'integrasi-dlh',
    loadChildren: () => import('./pages/integrasi-dlh/integrasi.module').then(m => m.KegiatanModule)
  },
  {
    path: 'integrasi-dinkes',
    loadChildren: () => import('./pages/integrasi-dinkes/integrasi.module').then(m => m.KegiatanModule)
  },
  {
    path: 'integrasi-bkd',
    loadChildren: () => import('./pages/integrasi-bkd/integrasi.module').then(m => m.KegiatanModule)
  },
  {
    path: 'integrasi-dpmptsp',
    loadChildren: () => import('./pages/integrasi-dpmptsp/integrasi.module').then(m => m.KegiatanModule)
  },

  {
    path: '**',
    loadChildren: () => import('./pages/errors/errors.module').then(m => m.ErrorsModule)
  }
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
