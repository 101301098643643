/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i3 from "ngx-perfect-scrollbar";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./services/seo.service";
import * as i7 from "./services/splash-screen.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "perfect-scrollbar", [["class", "bg-main"], ["style", "max-width: 100vw; max-height:100vh;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i2.View_PerfectScrollbarComponent_0, i2.RenderType_PerfectScrollbarComponent)), i1.ɵdid(1, 507904, null, 0, i3.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).autoPropagation; var currVal_1 = i1.ɵnov(_v, 1).scrollIndicators; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppComponent, [i4.Router, i6.SeoService, i4.ActivatedRoute, i7.SplashScreenService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
