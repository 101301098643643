import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
export class ErrorInterceptorService {
    constructor(auth) {
        this.auth = auth;
    }
    intercept(request, next) {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.auth.logout();
                location.reload();
            }
            const error = err.error || err.message || err.statusText;
            return throwError(error);
        }));
    }
}
ErrorInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorInterceptorService_Factory() { return new ErrorInterceptorService(i0.ɵɵinject(i1.AuthService)); }, token: ErrorInterceptorService, providedIn: "root" });
