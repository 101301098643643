import { HttpParams } from '@angular/common/http';
import { ImageUpload } from './image.upload';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class HelpersService {
    constructor(sb) {
        this.sb = sb;
        this.localToken = 'x-jbg-token';
        this.localUser = 'x-jbg-user';
    }
    sbError(message, action = null) {
        this.sb.open(message, action ? action : 'Galat', { duration: 5000 });
    }
    sbSuccess(message, action = null) {
        this.sb.open(message, action ? action : 'Sukses', { duration: 5000 });
    }
    getLimitParams(limit, offset) {
        return new HttpParams()
            .set('limit', limit)
            .set('offset', offset);
    }
    UploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new ImageUpload(loader, '/api/news/image');
        };
    }
}
HelpersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpersService_Factory() { return new HelpersService(i0.ɵɵinject(i1.MatSnackBar)); }, token: HelpersService, providedIn: "root" });
