import { animate, style } from '@angular/animations';
import { NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/animations";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
export class SplashScreenService {
    constructor(animationBuilder, document, router) {
        this.animationBuilder = animationBuilder;
        this.document = document;
        this.router = router;
        // Initialize
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _init() {
        // Get the splash screen element
        this.splashScreenEl = this.document.body.querySelector('#splash-screen');
        // If the splash screen element exists...
        if (this.splashScreenEl) {
            // Hide it on the first NavigationEnd event
            this.router.events
                .pipe(filter((event => event instanceof NavigationEnd)), take(1))
                .subscribe(() => {
                setTimeout(() => {
                    this.hide();
                });
            });
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Show the splash screen
     */
    show() {
        this.player =
            this.animationBuilder
                .build([
                style({
                    opacity: '0',
                    zIndex: '99999'
                }),
                animate('400ms ease', style({ opacity: '1' }))
            ]).create(this.splashScreenEl);
        setTimeout(() => {
            this.player.play();
        }, 0);
    }
    /**
     * Hide the splash screen
     */
    hide() {
        this.player =
            this.animationBuilder
                .build([
                style({ opacity: '1' }),
                animate('400ms ease', style({
                    opacity: '0',
                    zIndex: '-10'
                }))
            ]).create(this.splashScreenEl);
        setTimeout(() => {
            this.player.play();
        }, 0);
    }
}
SplashScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SplashScreenService_Factory() { return new SplashScreenService(i0.ɵɵinject(i1.AnimationBuilder), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.Router)); }, token: SplashScreenService, providedIn: "root" });
