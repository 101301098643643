import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
registerLocaleData(localeId, 'id');
const ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
export class AppModule {
}
export { ɵ0 };
