import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { ImageUpload } from './image.upload';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(private sb: MatSnackBar) { }
  localToken = 'x-jbg-token';
  localUser = 'x-jbg-user';
  sbError(message: string, action= null) {
    this.sb.open(message, action ? action : 'Galat', {duration: 5000});
  }
  sbSuccess(message: string, action= null) {
    this.sb.open(message, action ? action : 'Sukses', {duration: 5000});
  }
  getLimitParams(limit, offset): HttpParams {
    return new HttpParams()
    .set('limit', limit)
    .set('offset', offset);
  }
  UploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new ImageUpload(loader, '/api/news/image');
    };
  }
}
