import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class SeoService {
    constructor(title, meta) {
        this.title = title;
        this.meta = meta;
    }
    updateTitle(title = null) {
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'og:title', content: title });
        this.meta.updateTag({ name: 'og:locale', content: 'id' });
        this.meta.updateTag({ name: 'og:site_name', content: 'Portal Resmi Pemerintah Kabupaten Jombang' });
    }
    updateMetaInfo(desc, keywords, url) {
        this.meta.updateTag({ name: 'description', content: desc });
        if (keywords) {
            this.meta.updateTag({ name: 'keywords', content: keywords });
        }
        this.meta.updateTag({ name: 'og:url', content: url });
        this.meta.updateTag({ name: 'og:description', content: desc });
    }
}
SeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta)); }, token: SeoService, providedIn: "root" });
