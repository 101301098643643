import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class HttpsInterceptorService {
    intercept(req, next) {
        const match = /(\/(api|auth).+)/;
        const url = isDevMode() ? req.url.replace(match, `${environment.host}:${environment.port}$1`) : req.url.replace('http://', 'https://');
        const secure = req.clone({ url });
        return next.handle(secure);
    }
}
HttpsInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpsInterceptorService_Factory() { return new HttpsInterceptorService(); }, token: HttpsInterceptorService, providedIn: "root" });
