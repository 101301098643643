import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './services/seo.service';
import { SplashScreenService } from './services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Portal Resmi Pemerintah Kabupaten Jombang';
  constructor(
    private router: Router,
    private seo: SeoService,
    private activatedRoute: ActivatedRoute,
    private splash: SplashScreenService
  ) {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)).subscribe((event) => {
        this.seo.updateTitle(this.title);
      });
    // const menuStorage = ['backendMenu', 'landingMenu', 'infoUmum', 'infoLain', 'jaringan'];
    // menuStorage.forEach(menu => {
    //     if (localStorage.getItem(menu)) { localStorage.removeItem(menu); }
    //   });
    }
  }
