import { Routes } from '@angular/router';
const ɵ0 = () => import("./pages/landingpage/landingpage.module.ngfactory").then(m => m.LandingPageModuleNgFactory), ɵ1 = () => import("./pages/home/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ2 = () => import("./pages/berita/berita.module.ngfactory").then(m => m.BeritaModuleNgFactory), ɵ3 = () => import("./pages/download/download.module.ngfactory").then(m => m.DownloadModuleNgFactory), ɵ4 = () => import("./pages/kegiatan/kegiatan.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ5 = () => import("./pages/pengumuman/pengumuman.module.ngfactory").then(m => m.PengumumanModuleNgFactory), ɵ6 = () => import("./pages/galeri/galeri.module.ngfactory").then(m => m.GaleriModuleNgFactory), ɵ7 = () => import("./pages/wisata/wisata.module.ngfactory").then(m => m.WisataModuleNgFactory), ɵ8 = () => import("./pages/halaman/halaman.module.ngfactory").then(m => m.HalamanModuleNgFactory), ɵ9 = () => import("./pages/opd/opd.module.ngfactory").then(m => m.OpdModuleNgFactory), ɵ10 = () => import("./pages/sakip/sakip.module.ngfactory").then(m => m.SakipModuleNgFactory), ɵ11 = () => import("./pages/integrasi-jdih/integrasi.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ12 = () => import("./pages/integrasi-dlh/integrasi.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ13 = () => import("./pages/integrasi-dinkes/integrasi.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ14 = () => import("./pages/integrasi-bkd/integrasi.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ15 = () => import("./pages/integrasi-dpmptsp/integrasi.module.ngfactory").then(m => m.KegiatanModuleNgFactory), ɵ16 = () => import("./pages/errors/errors.module.ngfactory").then(m => m.ErrorsModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'home',
        loadChildren: ɵ1
    },
    {
        path: 'berita',
        loadChildren: ɵ2
    },
    {
        path: 'download',
        loadChildren: ɵ3
    },
    {
        path: 'kegiatan',
        loadChildren: ɵ4
    },
    {
        path: 'pengumuman',
        loadChildren: ɵ5
    },
    {
        path: 'galeri',
        loadChildren: ɵ6
    },
    {
        path: 'wisata',
        loadChildren: ɵ7
    },
    {
        path: 'pages',
        loadChildren: ɵ8
    },
    {
        path: 'opd',
        loadChildren: ɵ9
    },
    {
        path: 'sakip',
        loadChildren: ɵ10
    },
    {
        path: 'integrasi-jdih',
        loadChildren: ɵ11
    },
    {
        path: 'integrasi-dlh',
        loadChildren: ɵ12
    },
    {
        path: 'integrasi-dinkes',
        loadChildren: ɵ13
    },
    {
        path: 'integrasi-bkd',
        loadChildren: ɵ14
    },
    {
        path: 'integrasi-dpmptsp',
        loadChildren: ɵ15
    },
    {
        path: '**',
        loadChildren: ɵ16
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
