import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./helpers.service";
export class AuthService {
    constructor(http, router, helper) {
        this.http = http;
        this.router = router;
        this.helper = helper;
        this.token = localStorage.getItem(this.helper.localToken);
    }
    isLoggedIn() {
        if (localStorage.getItem(this.helper.localUser)) {
            return true;
        }
        else {
            return false;
        }
    }
    login(username, password) {
        const auth = 'Basic ' + btoa(`${username}:${password}`);
        const headers = new HttpHeaders().append('Authorization', auth);
        const url = '/auth/login';
        return this.http.get(url, { headers });
    }
    logout() {
        localStorage.removeItem(this.helper.localUser);
        localStorage.removeItem(this.helper.localToken);
        this.http.get('/auth/logout').subscribe(() => {
            this.router.navigate(['login']);
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelpersService)); }, token: AuthService, providedIn: "root" });
