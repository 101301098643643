import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../services/helpers.service";
export class JwtInterceptorService {
    constructor(helper) {
        this.helper = helper;
    }
    intercept(req, next) {
        // add authorization header with jwt token if available
        let request = req;
        if (!req.url.endsWith('login') && localStorage.getItem(this.helper.localToken)) {
            request = req.clone({
                headers: new HttpHeaders({
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    Pragma: 'no-cache',
                    Expires: '0',
                    Authorization: `Bearer ${localStorage.getItem(this.helper.localToken)}`
                })
            });
        }
        return next.handle(request);
    }
}
JwtInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptorService_Factory() { return new JwtInterceptorService(i0.ɵɵinject(i1.HelpersService)); }, token: JwtInterceptorService, providedIn: "root" });
